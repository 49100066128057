// 定義地圖預設值
// * 中心點
// * Zoom In 最大倍率

// export const Bovia = {
//   center: { lat: 24.807824129248665, lng: 121.03832092621745 },
//   zoom: 15
// }
export const Taiwan = { center: { lat: 24.137, lng: 120.683 }, zoom: 8 }
export const WholeWorld = { center: { lat: 0, lng: 0 }, zoom: 2.8 }

export const LoginMapCenter = JSON.parse(JSON.stringify(Taiwan))

// 當marker距離很接近時，放大倍率大增，需要再調整成zoom=15
export const MaxZoomIn = 15

const genUrl = () => {
  // for security
  let k1 = 'AIzaSyAjlV'
  let k2 = 'LN8XOg2V3n'
  let k3 = 'y6jyeF7jYE'
  let k4 = 'KyKMTrgFk'

  return `${k1}${k2}${k3}${k4}`
}

export const getMapUrl = () => {
  return genUrl()
}
