import i18n from '../i18n/i18n'
import {
  genKeepDayOptions,
  genMinutesOptions
  //, enum2Options
} from './lib.js'

// enum
export const euTF = {
  false: 0,
  true: 1
}

export const euAccountKind = {
  default: 0,
  sso: 1,
  device: 2,
  user: 3
}

export const isDevice = (kind) => {
  return kind === euAccountKind.default || kind === euAccountKind.device
}

export const isUser = (kind) => {
  return kind !== euAccountKind.device
}

// variable ===
// AccountManager
const euAccountKindStr = Object.keys(euAccountKind)
// const accountKindOptions = enum2Options(euAccountKind)
// AccountManager-user
export const userKindOptions = [
  {
    value: euAccountKind.default,
    label: euAccountKindStr[euAccountKind.default],
    disabled: true
  },
  {
    value: euAccountKind.sso,
    label: euAccountKindStr[euAccountKind.sso],
    disabled: true
  },
  { value: euAccountKind.user, label: euAccountKindStr[euAccountKind.user] }
]
// AccountManager-device
export const deviceKindOptions = [
  {
    value: euAccountKind.default,
    label: `account_kind_${euAccountKindStr[euAccountKind.default]}`,
    disabled: true
  },
  {
    value: euAccountKind.device,
    label: `account_kind_${euAccountKindStr[euAccountKind.device]}`
  }
]

export const keepOptions = genKeepDayOptions()

export const minutesOptions = genMinutesOptions(
  i18n.t('one_min'),
  i18n.t('min')
)
export const lockMinutesOptions = genMinutesOptions(
  i18n.t('one_min_later'),
  i18n.t('min_later')
)

export const userKind = {
  // User + Device
  default: {
    icon: 'device_type_userAndCamera',
    label: 'default',
    kind: 0
  },
  sso: {
    icon: 'user',
    label: 'SSO',
    kind: 1
  },
  // device: {,,kind:2}
  user: {
    icon: 'user',
    label: 'user',
    kind: 3
  }
}
export const userKindList = Object.values(userKind)
